<template>
  <div class="fundModule">
    <rxNavBar title="目标统计"></rxNavBar>
    <div class="titleMain">
      {{
       ( managementbyobjectivesIn.mBOName ? managementbyobjectivesIn.mBOName : '' ) +
        " - " +
       ( managementbyobjectivesIn.statusName ? managementbyobjectivesIn.statusName : '')
      }}
    </div>
    <div class="chufangCon" v-if="managementbyobjectivesIn.rentOutHouse == 1">
      <div class="chufangCont1">出房目标</div>
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem11">排名</div>
            <div class="chufangConcTitleItem21">店组</div>
            <div class="chufangConcTitleItem31">目标</div>
            <div class="chufangConcTitleItem41">完成</div>
            <div class="chufangConcTitleItem51">完成率</div>
          </div>
           <div v-if="!chufangList || chufangList.length == 0" >
            <div class="emptyCon">
                <img class="imgon" src="../../assets/images/empty.png" alt="">   
            </div>
             <div class="emptyCon1">啊哦，这里好像什么都没有</div>
          </div>

          <div
            v-else
            v-for="(items, indexs) in chufangList"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1 }}</div>
            <div class="chufangConcTitleItem2">{{ items.departmentName }}</div>
            <div class="chufangConcTitleItem3">{{ items.rentOutHouse ? items.rentOutHouse : 0 }}</div>
            <div class="chufangConcTitleItem4">
              {{ items.actualRentOutHouse ? items.actualRentOutHouse : 0 }}
            </div>
            <div
              :style="Number(items.outHousel) >= 100 ? color1 : color2"
              class="chufangConcTitleItem5"
            >
              {{ String(items.outHousel ? items.outHousel.toFixed(2) : 0) + "%" }}
            </div>
          </div>
          
        </div>
      </div>
      <div v-if="chufangList && chufangList.length != 0" class="chufangConb">
        <span @click="showMore('cf', 'hourse')">更多...</span>
      </div>
    </div>
    <div class="chufangCon" v-if="managementbyobjectivesIn.receiveHouse == 1">
      <div class="chufangCont1">收房目标</div>
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem11">排名</div>
            <div class="chufangConcTitleItem21">店组</div>
            <div class="chufangConcTitleItem31">目标</div>
            <div class="chufangConcTitleItem41">完成</div>
            <div class="chufangConcTitleItem51">完成率</div>
          </div>
           <div v-if="!shoufangList || shoufangList.length == 0" >
            <div class="emptyCon">
                <img class="imgon" src="../../assets/images/empty.png" alt="">   
            </div>
             <div class="emptyCon1">啊哦，这里好像什么都没有</div>
          </div>

          <div
            v-else
            v-for="(items, indexs) in shoufangList"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1 }}</div>
            <div class="chufangConcTitleItem2">{{ items.departmentName }}</div>
            <div class="chufangConcTitleItem3">{{ items.receiveHouse ? items.receiveHouse : 0 }}</div>
            <div class="chufangConcTitleItem4">
              {{ items.actualReceiveHouse ? items.actualReceiveHouse : 0 }}
            </div>
            <div
              :style="Number(items.inHousel) >= 100 ? color1 : color2"
              class="chufangConcTitleItem5"
            >
              {{ String(items.inHousel ? items.inHousel.toFixed(2) : 0) + "%" }}
            </div>
          </div>
          
        </div>
      </div>
      <div v-if="shoufangList && shoufangList.length != 0" class="chufangConb">
        <span @click="showMore('sf', 'hourse')">更多...</span>
      </div>
    </div>



    <div class="chufangCon" v-if="managementbyobjectivesIn.interestMargin == 1">
      <div class="chufangCont1">首次利差目标</div>
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem1 fontweight">排名</div>
            <div class="chufangConcTitleItem16 fontweight">店组</div>
            <div class="chufangConcTitleItem17 fontweight">目标</div>
            <!-- <div class="chufangConcTitleItem4">完成</div> -->
            <div class="chufangConcTitleItem7 fontweight">实际利差</div>
          </div>
          <div v-if="!firstLicha || firstLicha.length == 0" >
            <div class="emptyCon">
                <img class="imgon" src="../../assets/images/empty.png" alt="">   
            </div>
             <div class="emptyCon1">啊哦，这里好像什么都没有</div>
          </div>

          <div
          v-else
            v-for="(items, indexs) in firstLicha"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1 }}</div>
            <div class="chufangConcTitleItem16">{{ items.departmentName }}</div>
            <div class="chufangConcTitleItem17">{{ (items.interestMargin || items.interestMargin === 0)  ? items.interestMargin : '暂无数据' }}</div>
            <!-- <div class="chufangConcTitleItem4">{{items.wancheng}}</div> -->
            <div
              :style="Number(items.actualInterestMargin) < items.interestMargin ? color3 : color2"
              class="chufangConcTitleItem7"
            >
              {{ (items.actualInterestMargin || items.actualInterestMargin === 0) ? items.actualInterestMargin : '暂无数据' }}
            </div>
          </div>
          
        </div>
      </div>
      <div v-if="firstLicha && firstLicha.length != 0" class="chufangConb">
        <span @click="showMore('sclc', 'licha')">更多...</span>
      </div>
    </div>

    <div class="chufangCon" v-if="managementbyobjectivesIn.averageInterestMargin == 1">
      <div class="chufangCont1">平均利差目标</div>
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem1 fontweight">排名</div>
            <div class="chufangConcTitleItem16 fontweight">店组</div>
            <div class="chufangConcTitleItem17 fontweight">目标</div>
            <!-- <div class="chufangConcTitleItem4">完成</div> -->
            <div class="chufangConcTitleItem7 fontweight">实际利差</div>
          </div>
          <div v-if="!lc || lc.length == 0" >
            <div class="emptyCon">
                <img class="imgon" src="../../assets/images/empty.png" alt="">   
            </div>
             <div class="emptyCon1">啊哦，这里好像什么都没有</div>
          </div>

          <div
          v-else
            v-for="(items, indexs) in lc"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1 }}</div>
            <div class="chufangConcTitleItem16">{{ items.departmentName }}</div>
            <div class="chufangConcTitleItem17">{{ (items.averageInterestMargin || items.averageInterestMargin === 0) ? items.averageInterestMargin : '暂无数据' }}</div>
            <!-- <div class="chufangConcTitleItem4">{{items.wancheng}}</div> -->
            <div
              :style="Number(items.actualAverageInterestMargin) < items.averageInterestMargin ? color3 : color2"
              class="chufangConcTitleItem7"
            >
              {{ (items.actualAverageInterestMargin || items.actualAverageInterestMargin === 0) ? items.actualAverageInterestMargin : '暂无数据' }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="lc && lc.length != 0" class="chufangConb">
        <span @click="showMore('pjlc', 'licha')">更多...</span>
      </div>
    </div>
    <div style="height: 50px"></div>
    <!-- <div class="lichaCon">
            <div class="chufangCont">
                利差目标
            </div>
            <div class="lichaConb">
                <div class="lichaConl">
                    <div class="lichaConlt">
                        <div class="lichaConltl">
                            首次
                        </div>
                        <div class="lichaConltr">
                            260
                        </div>
                    </div>
                    <div class="lichaConlb">
                        170
                    </div>
                </div>
                <div class="lichaConr">
                    <div class="lichaConlt1">
                        <div class="lichaConltl">
                            平均利差
                        </div>
                        <div class="lichaConltr">
                            260
                        </div>
                    </div>
                    <div class="lichaConlb">
                        80
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>
<script>
import {
  DatetimePicker,
  Popup,
  Sticky,
  Picker,
  Tab,
  Tabs,
  TreeSelect,
  Col,
  Row,
  Button,
  List,
  Cell,
} from "vant";
import { managementByObjectives } from "../../getData/getData";
import {
  responseUtil,
  addMonth,
  getStaffId,
  checkAndroid,
  checkIOS,
} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";

export default {
  name: "businessModule",
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
    [DatetimePicker.name]: DatetimePicker,
    [List.name]: List,
    [Cell.name]: Cell,
    rxNavBar,
  },
  data() {
    return {
      androidFlg: "",
      titleFont: "大连2023年度目标",
      titletype: "进行中",
      chufangList: [
        // {index: 1, dianzu:'大连国际租房协会NO11111111111组',mubiao: 5000,wancheng: 6000,wanchenglv: '6000' },
        // {index: 2, dianzu:'大连国际租房协会NO1111组',mubiao: 5000,wancheng: 6000,wanchenglv: '120' },
        // {index: 3, dianzu:'大连国际租房协会',mubiao: 5000,wancheng: 6000,wanchenglv: '100' },
        // {index: 4, dianzu:'大连国际租房协会NO1111组',mubiao: 5000,wancheng: 6000,wanchenglv: '99' },
        // {index: 5, dianzu:'大连国际租房协会NO1111组',mubiao: 5000,wancheng: 6000,wanchenglv: '88' },
      ],
      shoufangList: [],
      licahList:[],
      firstLicha: [],
      lc: [],
      color1: {
        color: "green",
      },
      color2: {
        color: "black",
      },
      color3: {
        color: "red",
      },
      formData: {},
      formId: "",
      managementbyobjectivesIn: {},
    };
  },
  mounted() {
    this.formId = JSON.parse(localStorage.getItem("hourse_id_type"))
      ? JSON.parse(localStorage.getItem("hourse_id_type"))
      : "";
    console.log(this.formId, "this.formId===");
    // if(this.$route.query.form) {
    //     this.formData = this.$route.query.form
    //     console.log(this.formData,"this.formData===")
    // }
    this.checkPhoneorMobel();
    this.initData();
  },
  methods: {
    initData() {
      var that = this;
      const params = {
        user_id: getStaffId(),
        id: that.formId,
      };
      managementByObjectives(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.chufangList = res.data.data.outHouse;
          that.shoufangList = res.data.data.inHouse;
          that.firstLicha = res.data.data.firstLicha;
          that.lc = res.data.data.lc;
          that.managementbyobjectivesIn = res.data.data.managementbyobjectives;
          console.log(res.data.data, "sdadsdsad===");
        });
      });
    },
    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true;
      } else if (checkIOS()) {
        this.androidFlg = false;
      }
    },
    showMore(type, typeofs) {
      this.$router.push({
        name: "targetStatisticsDetail",
        query: {
          cstype: type,
          typeOf: typeofs,
          form: this.formId,
        },
      });
    },
    // showMore1(type) {
    //     this.$router.push({
    // 		name: 'targetStatisticsDetaillc',
    //         query: {
    // 			cstype: type
    // 		}
    // 	})
    // }
  },
};
</script>
<style scoped>
.fundModule {
  font-size: 12px;
  /* font-weight:bold; */
  background: #fafafa;
}
.titleMain {
  margin: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: skyblue; */
  font-size: 15px;
  font-weight: 600;
  color: #fe5e3a;
}
.chufangCon {
  margin: 5px 10px;
  height: 295px;
  /* background: pink; */
  display: flex;
  flex-direction: column;
}
.chufangCont {
  height: 30px;
  /* background: #ccc; */
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;
}
.chufangCont1 {
  font-weight: bold;

  /* background: #ccc; */
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 20px;
}
.chufangConc {
  flex: 1;
  background: #fff;
  border-radius: 10px;
}
.chufangConb {
  height: 30px;
  /* background: #f60; */
  color: #fe5d3b;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.lichaCon {
  margin: 5px 10px;
  /* height: 200px; */
  /* background: green; */
}
.lichaConb {
  height: 150px;
  display: flex;
}
.lichaConl {
  flex: 1;
  background: #fcf4f1;
  margin-right: 10px;
}
.lichaConr {
  flex: 1;
  background: #edf3ff;
  margin-left: 10px;
}
.lichaConlt {
  height: 40px;
  background: #f6c48e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lichaConlt1 {
  height: 40px;
  background: #c1dffb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lichaConlb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-weight: 600;
  height: 120px;
}
.lichaConltl {
  font-size: 15px;
}
.lichaConltr {
  font-size: 15px;
}
.chufangConcTitle {
  display: flex;
  height: 40px;
  font-size: 14px;

  justify-content: space-around;
  align-items: center;
}
.chufangConcTitleItem1 {
  width: 10%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem2 {
  width: 43%;
  height: 100%;
  /* line-height: 100%; */
  /* text-align: center; */
  /* line-height: 250%; */
  /* white-space: wrap; */
  /* overflow: hidden;
        text-overflow: ellipsis; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem3 {
  width: 15%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem4 {
  width: 15%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem5 {
  width: 17%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  /* border-bottom: 1px solid #FAFAFA; */
}
.chufangConcTitleItem6 {
  width: 55%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem16 {
  width: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem7 {
  width: 20%;
  height: 100%;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem17 {
  width: 20%;
  height: 100%;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem11 {
  width: 10%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  font-weight: bold;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem21 {
  width: 43%;
  height: 100%;
  /* line-height: 100%; */
  /* text-align: center; */
  line-height: 250%;
  /* white-space: wrap; */
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem31 {
  width: 15%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem41 {
  width: 15%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem51 {
  width: 17%;
  height: 100%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  line-height: 250%;
  border-right: 1px solid #fafafa;
  /* border-bottom: 1px solid #FAFAFA; */
}
.chufangConcTitleList {
  /* border-left: 1px solid #FAFAFA;
        border-top: 1px solid #FAFAFA; */
  margin-top: 5px;
  /* border-radius: 10px; */
}
.fontweight {
  font-weight: bold;
}
.emptyCon {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.emptyCon1 {
    height: 25px;
    color:#ACACAC;
    font-size: 12px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.imgon {
    width: 90px;
    height: 71px;
}
</style>
